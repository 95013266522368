import React from "react";
import ReactPlayer from "react-player";

const YouTubePlayer = () => {
  return (
      <ReactPlayer
        url="https://youtube.com/shorts/hcrCjbXit_0"
        controls
        playing
        height="25rem"
        width={"25rem"}	

      />
  );
};

export default YouTubePlayer;
