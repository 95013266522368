import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './DemoGeneric.css'; // Renamed CSS file for clarity
import TestSolution from './images/testsolution.webp';
import ReactDOM from 'react-dom/client';

const GenericAIPage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Extract chatbot_id from the URL query string
    const urlParams = new URLSearchParams(window.location.search);
    const chatbotId = urlParams.get("id"); // Get the 'id' parameter
    const account = urlParams.get("acc")
    if (chatbotId) {
      // Use the chatbotId dynamically
      window.embeddedChatbotConfig = {
        chatbotId: chatbotId,
        account: account,
      };
          // Function to dynamically load the script
    const loadScript = (src, onLoad) => {
      const script = document.createElement('script');
      script.src = src;
      script.defer = true;
      script.onload = onLoad;
      document.body.appendChild(script);
    };

    // Load the chat widget bundle
    loadScript('/chat-widget-bundle.js', () => {
      console.log("Chat widget bundle loaded");
      ReactDOM.createRoot(document.getElementById('aissie-chat'));
      // Add your React rendering logic here, e.g.,
      // root.render(<ChatComponent />);
    });
    } else {
      console.log("No chatbot ID found in the URL.");
    }

    // Optional: Dynamically load chat widget script if necessary
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Generate structured data for the AI Assistant page
  const generateAIPageSchema = () => {
    const aiPageSchema = {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      name: 'AI Assistant demonstration',
      description:
        "AIssie virtual assistant Demonstration.",
      url: 'https://www.aissie.com.au/demonstration',
      image: 'https://www.aissie.com.au/images/testsolution.webp', // Update with the correct image URL
    };
    return JSON.stringify(aiPageSchema);
  };

  // Generate breadcrumb structured data
  const generateBreadcrumbSchema = () => {
    const breadcrumbSchema = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Home',
          item: 'https://www.aissie.com.au/',
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: 'AI Assistant Demonstration',
          item: 'https://www.aissie.com.au/demonstration',
        },
      ],
    };
    return JSON.stringify(breadcrumbSchema);
  };

  return (
    <div className="generic-ai-container">
      <Helmet>
        <title>AI Assistant Demonstration</title>
        <meta
          name="description"
          content="AIssie assistant demonstration"
        />
        <link rel="canonical" href="https://www.aissie.com.au/demonstration" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: generateAIPageSchema() }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: generateBreadcrumbSchema() }}
        />
      </Helmet>

      <header className="first-screen-generic-ai">
        <div className="ai-text-block">
          <h1>AIssie Assistant Demonstration</h1>

        </div>

        <div className="image-placeholder">
          <img
            src={TestSolution}
            alt="AI Assistant demonstrating FAQ functionality"
            className="hero-image-faq"
          />
        </div>
      </header>

      <footer>
        <div id="aissie-chat"></div>
      </footer>
    </div>
  );
};

export default GenericAIPage;
