import React from 'react';
import './About.css';

import Core1 from './images/innovation.webp';
import Core2 from './images/integrity.webp';
import Core3 from './images/customer.webp';
import MiniCard from './MiniCard';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Youtube from './Youtube';

const AboutUsPage = () => {
    const navigate = useNavigate();

    const handleTryNowClick = () => {
        // Push the event to dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'tryNowClick',
          'eventCategory': 'Button',
          'eventAction': 'Click',
          'eventLabel': 'Try Now'
        });
        navigate('/pricing')
      };

    return (
        <main className="aboutus-page">
            <Helmet>
                <title>About Us - AIssie</title>
                <meta
                    name="description"
                    content="Learn more about AIssie, our mission, vision, and the team dedicated to providing top-notch AI assistant solutions."
                />
                <link rel="canonical" href="https://www.aissie.com.au/about-us" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.aissie.com.au/about-us" />
                <meta property="og:title" content="About Us - AIssie" />
                <meta
                    property="og:description"
                    content="Discover the mission, vision, and team behind AIssie, your trusted AI assistant solution provider."
                />
                <meta property="og:image" content="https://www.aissie.com.au/about-us-image.png" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://www.aissie.com.au/about-us" />
                <meta name="twitter:title" content="About Us - AIssie" />
                <meta
                    name="twitter:description"
                    content="Learn more about AIssie, our mission, vision, and the team dedicated to providing top-notch AI assistant solutions."
                />
                <meta name="twitter:image" content="https://www.aissie.com.au/about-us-image.png" />

                <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "AIssie",
            "url": "https://www.aissie.com.au",
            "logo": "https://www.aissie.com.au/favicon.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+61499712235",
              "contactType": "Customer Support"
            },
            "sameAs": [
              "https://www.linkedin.com/company/aissie"
            ],
            "description": "AIssie provides real-time AI assistant solutions to streamline customer enquiries and improve overall customer experience."
          }
        `}</script>
            </Helmet>

            {/* Hero Section */}
            <section className="hero-section">
                <div className="hero-text-block">
                    <h1>WHO WE ARE</h1>
                    <p>
                        AIssie was founded in Perth, Australia, with the goal of transforming customer service through AI. Our mission is to empower businesses with intelligent assistants that enhance
                        customer interactions, streamline operations, and drive growth.
                    </p>
                    <button type="button" className='button-home' onClick={handleTryNowClick}>
          Start Your Free Trial
          </button>
                </div>
                <div className="hero-image">
                    <Youtube />
                </div>
            </section>

            {/* Mission and Vision */}
            <section className="mission-vision">
                <div className='mission-vision-core-div'>
                    <div className="mission-vision-div">
                        <div className="mission">
                            <h2>Our Mission</h2>
                            <p>
                                To provide innovative AI solutions that simplify customer interactions, enhance user
                                experiences, and drive business efficiency across various industries.
                            </p>
                        </div>
                        <div className="vision>">

                            <h2>Our Vision</h2>
                            <p>
                                To be the leading provider of AI-powered assistants in Australia, transforming the way
                                businesses engage with their customers and operate internally.
                            </p>

                        </div>
                    </div>
                    <div className="cores">
                    <h2>Our Core Values</h2>

                        <div className="benefit-items">


                            <article className="core-item">

                                <MiniCard
                                    className="core-card"
                                    frontContent={
                                        <>
                                            <h4>Innovation</h4>
                                            <div className="benefits-image">
                                                <img src={Core1} alt="Innovation" loading="lazy" />
                                            </div>
                                            <div className="cardDetails">
                                                <p>Learn More</p>
                                            </div>
                                        </>
                                    }
                                    backContent={
                                        <p>
                                            We continuously seek innovative solutions to stay ahead in the rapidly evolving AI landscape.
                                        </p>
                                    }
                                />
                            </article>
                            <article className="core-item">

                                <MiniCard
                                    className="core-card"
                                    frontContent={
                                        <>
                                            <h4>Integrity</h4>
                                            <div className="benefits-image">
                                                <img src={Core2} alt="Integrity" loading="lazy" />
                                            </div>
                                            <div className="cardDetails">
                                                <p>Learn More</p>
                                            </div>
                                        </>
                                    }
                                    backContent={
                                        <p>
                                            We uphold the highest standards of integrity in all our actions, ensuring trust and reliability.
                                        </p>
                                    }
                                />
                            </article>
                            <article className="core-item">

                                <MiniCard
                                    className="core-card"
                                    frontContent={
                                        <>
                                            <h4>Customer-Centric</h4>
                                            <div className="benefits-image">
                                                <img src={Core3} alt="Customer-Centric" loading="lazy" />
                                            </div>
                                            <div className="cardDetails">
                                                <p>Learn More</p>
                                            </div>
                                        </>
                                    }
                                    backContent={
                                        <p>
                                            Our customers are at the heart of everything we do. We strive to exceed their expectations.
                                        </p>
                                    }
                                />
                            </article>
                        </div>
                    </div>

                </div>

            </section>


            {/* Call to Action */}
            <section className="cta-section">
                <h2>Join Us in Transforming Customer Service</h2>
                <p>
                    Ready to enhance your customer interactions with AI-powered solutions? Get in touch with our
                    team today to learn more about how AIssie can benefit your business.
                </p>
                <button type="button" className='button-home' onClick={handleTryNowClick}>
          Start Your Free Trial
          </button>
            </section>
        </main >
    );
};

export default AboutUsPage;
