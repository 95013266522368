import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './DemoOSvC.css'; // Renamed CSS file for clarity
import TestSolution from './images/testsolution.webp';
import ReactDOM from 'react-dom/client';

const OSvCAIPage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    window.embeddedChatbotConfig = {
      chatbotId: '3cf929e2-6d95-40f4-b3c2-4fd1ec281672',
      account: 'AISSIE',
    };
        // Function to dynamically load the script
        const loadScript = (src, onLoad) => {
          const script = document.createElement('script');
          script.src = src;
          script.defer = true;
          script.onload = onLoad;
          document.body.appendChild(script);
        };
    
        // Load the chat widget bundle
        loadScript('/chat-widget-bundle.js', () => {
          console.log("Chat widget bundle loaded");
          ReactDOM.createRoot(document.getElementById('aissie-chat'));
          // Add your React rendering logic here, e.g.,
          // root.render(<ChatComponent />);
        });
    // Optional: Dynamically load chat widget script if necessary
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Generate structured data for the AI Assistant page
  const generateAIPageSchema = () => {
    const aiPageSchema = {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      name: 'Oracle B2C Service Cloud AI Assistant',
      description:
        'Explore Oracle Service Cloud AI Assistant, designed to provide information about the Oracle CRM. It offers answers to technical queries about the product.',
      url: 'https://www.aissie.com.au/osvc-demo',
      image: 'https://www.aissie.com.au/images/testsolution.webp', // Update with the correct image URL
    };
    return JSON.stringify(aiPageSchema);
  };

  // Generate breadcrumb structured data
  const generateBreadcrumbSchema = () => {
    const breadcrumbSchema = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': [
        {
          '@type': 'ListItem',
          'position': 1,
          'name': 'Home',
          'item': 'https://www.aissie.com.au/',
        },
        {
          '@type': 'ListItem',
          'position': 2,
          'name': 'Oracle B2C Service Cloud AI Assistant',
          'item': 'https://www.aissie.com.au/osvc-demo',
        },
      ],
    };
    return JSON.stringify(breadcrumbSchema);
  };

  return (
    <div className="osvc-ai-container">
      <Helmet>
        <title>Oracle B2C Service Cloud AI Assistant</title>
        <meta
          name="description"
          content="Explore Oracle Service Cloud AI Assistant, designed to provide information about the Oracle CRM. It offers answers to technical queries about the product."
        />
        <link rel="canonical" href="https://www.aissie.com.au/osvc-demo" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: generateAIPageSchema() }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: generateBreadcrumbSchema() }}
        />
      </Helmet>

      <header className="first-screen-osvc-ai">
        <div className="ai-text-block">
          <h1>Oracle B2C Service Cloud AI Assistant</h1>
          <p className="hero_subhead">
            Welcome to the Oracle B2C Service Cloud AI Assistant Demo! This assistant provides
            technical information about the Oracle Service Cloud application. The assistant has been
            trained using product documentation data and FAQs.
          </p>
        </div>

        <div className="image-placeholder">
          <img
            src={TestSolution}
            alt="AI Assistant demonstrating FAQ functionality"
            className="hero-image-faq"
          />
        </div>
      </header>

      <footer>
        <div id="aissie-chat"></div>
      </footer>
    </div>
  );
};

export default OSvCAIPage;
